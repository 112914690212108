// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-datenschutzerklarung-jsx": () => import("./../../../src/pages/datenschutzerklärung.jsx" /* webpackChunkName: "component---src-pages-datenschutzerklarung-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-virtuelle-server-jsx": () => import("./../../../src/pages/virtuelle_server.jsx" /* webpackChunkName: "component---src-pages-virtuelle-server-jsx" */),
  "component---src-pages-web-hosting-jsx": () => import("./../../../src/pages/web_hosting.jsx" /* webpackChunkName: "component---src-pages-web-hosting-jsx" */),
  "component---src-pages-zimbra-jsx": () => import("./../../../src/pages/zimbra.jsx" /* webpackChunkName: "component---src-pages-zimbra-jsx" */)
}

